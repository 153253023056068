<template>
  <router-view />
</template>

<style>
*{
    margin: 0;
    padding: 0;
}
html,
body,#app {
  margin: 0;
  padding: 0;
  /*height: 100%;*/
  background: #F5F5FC;
}

.w {
  width: 92%;
  margin: 0 auto;
}

/* ---- header区域 ---- */
.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 98px;
  background: #FFFFFF;
  box-shadow: 0 -12px 16px 1px rgba(0,0,0,0.06);
  opacity: 1;
  z-index: 10;
}

.header-nav {
  position: relative;
  color: #222;
  font-size: 19px;
  text-align: center;
  line-height: 53px;
}

.header-nav-back {
  position: absolute;
  left: 10px;
  width: 19px;
  height: 16px;
}

.submit-btn {
  font-size: 17px;
  color: #ffffff;
  width: 345px;
  margin: 25px auto 0;
  height: 50px;
  text-align: center;
  background: #409EFF;
  border-radius: 24px;
  opacity: 1;
}

.header-seat{
  height: 98px;
}


 /*底部导航栏样式*/
.occupy-box{
  height: 61px;
  width: 100%;
}
.van-tabbar {
  height: 61px !important;
}

.van-tabbar-item__icon {
  margin-top: 3px;
  margin-bottom: 7px;
}

.van-tabbar-item__text {
  font-size: 14px;
  color: #333742;
}

/*底部栏 选中的文字颜色*/
.van-tabbar-item--active .van-tabbar-item__text {
  font-size: 14px;
  color: #409EFF;
}

/*暂无数据样式*/
.noData {
  padding: 20px 0;
  text-align: center;
  font-size: 15px;
  color: #A1A7BA;
}

/*底部栏*/
.navIcon {
  height: 21px;
  width: 21px;
}

.van-tabbar--fixed {
  z-index: 99 !important;
}

/*列表页搜索和更多搜索条件图标*/
.search-btn img {
  position: relative;
  top: 2px;
  width: 14px;
}

.more-search img {
  position: relative;
  top: 2px;
  width: 15px;
}

.info-label {
  white-space: nowrap;
}

/*暂无数据*/
.empty-data {
  width: 345px;
  height: 55px;
  margin: 15px auto;
  background: #fff;
  color: #A1A7BA;
  font-size: 15px;
  text-align: center;
  line-height: 55px;
  border-radius: 10px;
  box-shadow: 0 3px 20px rgba(108, 157, 209, 0.16);
}
</style>
