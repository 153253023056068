import axios from 'axios'
import router from "@/router";
import {Notify} from 'vant';
//1.创建axios实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  responseType: 'json',
})
//2.拦截器
instance.interceptors.request.use(config => {
  var token = sessionStorage.getItem('token')
  // var token ="bearer " + sessionStorage.getItem('token')
  // var token = "bearer " + "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjE5LCJhdWQiOiIiLCJleHAiOjE2NDY0MTQxMzgsImlhdCI6MTY0NjM1NDEzOCwiaXNzIjoiIiwianRpIjoiN2ZkOGZhYzRhYmZkMTA5OTNlNzk5NTI3YTljOTMzZjEiLCJuYmYiOjE2NDYzNTQxMzgsInN1YiI6IiJ9.WIXfTI0MilRuzJf7du1_a9xj9vjunuIwu34rWkklB20";
  if (token) {
    config.headers.Authorization = token
  }
  return config
}, err => {
})
//反应拦截器拦截
instance.interceptors.response.use(response => {
  let resCode = response.data.code
  if (resCode === 105 || resCode === 101 || resCode === 102 || resCode === 403){
    Notify({type: 'danger', message: response.data.message,})
    if (localStorage.getItem('style') === 'ding'){
      sessionStorage.clear()
      router.push({
        path: '/dingTalkLogin'
      })
      // setTimeout(function (){
      //   router.push('/dingLogin?dingTalkId=' + localStorage.getItem('dingId'))
      //   sessionStorage.clear()
      // },2000)
    }else if(localStorage.getItem('style') === 'weChat'){
      sessionStorage.clear()
      router.push({
        path: '/weChatLogin'
      })
    }
    return
  }
  // // 版本判断
  if (!sessionStorage.getItem('update_btn')) {
    sessionStorage.setItem('update_btn', '0')
  }
  // 储存版本号
  if (!sessionStorage.getItem('version')) {
    sessionStorage.setItem('version', response.data.version)
  }
  let version = sessionStorage.getItem('version')
  if (version && response.data.version && version !== response.data.version) {
    sessionStorage.setItem('update_btn', '1')
  }
  return response.data;
}, err => {
  console.log(err);
})
export const request = instance
